@media screen and (max-width: 768px) {
  #features {
    padding: 20px;
    /* width: 111%; */
    width: auto;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    /* width: 111%; */
    width: auto;
  }

  #portfolio {
    /* width: 110%; */
    width: auto;
  }
}


.space {
  margin: auto;
  width: auto;
}

.glowing-text {
  color: #fff; /* Set the text color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Add a glowing effect with text shadow */
  animation: glow 1s infinite alternate; /* Add a glowing animation */
}

.pop-out {
  animation: pop 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards; /* Add a pop-out animation and retain the final state */
  transform-origin: center;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}